<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item @click="$router.go(-1)"
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">套餐</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="contentList">
      <div
        class="setMealList"
        v-for="(item, index) in setMealList"
        :key="index"
      >
        <!-- ?productId=Wybnek6xORu6Ccn-UvV-KbWHarpdusx4oJztfFgCLTw&name=电子书城 -->
        <div class="contentItem">
          <img :src="item.pcMobileListPicture" alt="" />
          <div class="content">
            <div class="left">
              <p class="name onlyOneHidden" @click.stop="
                  $router.push(
                    '/knowledgeMall/setMealDetail?packageId=' +
                      $AES.encode_data(item.packageId + '') +
                      '&name=套餐'
                  )
                ">
                <span>套餐</span>{{ item.packageName }}
              </p>
              <div class="navs" v-show="item.couponList && item.couponList.length">
                <!-- <p class="type">{{item.describe}}</p> -->
                <span
                  class="nav"
                  v-show="item.couponPrice"
                  v-for="(ite, ind) in item.couponList"
                  :key="ind"
                >
                  {{ ite }}
                </span>
              </div>
              <p class="introduce mallHidden">{{ item.introduction }}</p>
            </div>
            <div class="right">
              <!-- <p class="positionTitle">套餐价：</p> -->
              <div class="price">
                <p class="new">
                  ￥<span>{{ managePrice(item.packagePrice)[0] }}<span class="new_point">.{{managePrice(item.packagePrice)[1]}}</span></span>
                </p>
                <p class="old">原价：￥{{ (item.originalPrice * 1).toFixed(2) }}</p>
              </div>
              <!-- visible = true,fontText = '系统升级，请下载华夏云课堂app进行购买' -->
              <div
                class="button"
                @click.stop="
                  $router.push(
                    '/knowledgeMall/setMealDetail?packageId=' +
                      $AES.encode_data(item.packageId + '') +
                      '&name=套餐'
                  )
                "
              >
                <p>立即购买</p>
              </div>
            </div>
          </div>
        </div>
        <div class="setMealShop">
          <template v-if="item.productList.length > 5">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(ite, ind) in item.productList" :key="ind">
                <div @click="routerPush(ite)" class="industryitem">
                  <div class="shopItem">
                    <img class="img" :src="ite.picture" alt="" />
                    <p class="name mallHidden">{{ ite.name }}</p>
                    <p class="oldPrice">
                      <span><span style="font-size: 12px;">￥</span>{{ ite.originalPrice.toFixed(2) }}</span>
                    </p>
                  </div>

                  <a-icon
                    v-if="ind != item.productList.length - 1"
                    style="font-size: 24px; color: #15b7dd"
                    class="icon"
                    type="plus"
                  />
                </div>
              </swiper-slide>
              <!-- 前进后退按钮 -->
              <div
                class="swiper-button-prev swiper_btn swiper-button-white"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next swiper_btn swiper-button-white"
                slot="button-next"
              ></div>
            </swiper>
          </template>
          <div
            class="item"
            v-else
            v-for="(ite, ind) in item.productList"
            :key="ind"
            @click="routerPush(ite)"
          >
            <div class="shopItem">
              <img class="img" :src="ite.picture" alt="" />
              <p class="name mallHidden">{{ ite.name }}</p>
              <p class="oldPrice">
                <!-- 原价： --><span><span style="font-size: 12px;">￥</span>{{ ite.originalPrice.toFixed(2) }}</span>
              </p>
            </div>
            <!-- <a-icon
              v-if="ind != item.productList.length - 1"
              style="font-size: 24px; color: #15b7dd"
              class="icon"
              type="plus"
            /> -->

            <img
              v-if="ind != item.productList.length - 1"
              style="width: 24px; height: 24px"
              class="icon"
              type="plus"
              src="@/assets/image/20220607-143754.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <a-pagination
        show-quick-jumper
        :default-current="1"
        :total="total"
        @change="onChange"
      />
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      visible: false,
      fontText: "", // 文本
      tabMenu: ["全部", "213"],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      swiperOptions: {
        slidesPerView: 5,
        centeredSlides: true,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      setMealList: [],
      list: [
        {
          name: "孤独症儿童教育孤独症儿童教育",
          price: "1988",
          oldprice: "16555",
          img: require("@/assets/image/temporary/pic_nav.jpg"),
          discount: "满100减50,2件九折",
          describe: "上岗课程丨12课时",
          teacherName: "张苗苗,爱仕达",
          introduce:
            "课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育的基础理论、实际操作流程及上岗须知等。第二部分为实操课，第...",
          children: [
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
          ],
        },
        {
          name: "孤独症儿童教育孤独症儿童教育",
          price: "1988",
          oldprice: "16555",
          img: require("@/assets/image/temporary/pic_nav.jpg"),
          discount: "满100减50,2件九折",
          describe: "上岗课程丨12课时",
          teacherName: "张苗苗,爱仕达",
          introduce:
            "课程内容分为三部分。第一部分为理论课，介绍孤独症儿童康复教育的基础理论、实际操作流程及上岗须知等。第二部分为实操课，第...",
          children: [
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
            {
              name: "孤独症儿童教育孤独症儿童教育",
              oldprice: "16555",
              img: require("@/assets/image/temporary/pic_nav.jpg"),
            },
          ],
        },
      ],
    };
  },
  // 事件处理器
  methods: {
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split('.')
      return txt
    },
    routerPush(e) {
      if (e.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.code
        );
      } else if (e.type == 2 || e.type == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
      } else if (e.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
      }
    },
    // 分页
    onChange(pageNumber) {
      this.pageNum = pageNumber;
      this.getsetmealList();
    },
    // 获取套餐列表
    getsetmealList() {
      this.$ajax({
        url: "/hxclass-pc/pc-packages/pc/list",
        method: "get",
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.setMealList = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getsetmealList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
::v-deep.swiper-container {
  margin: 0;
  width: 100%;
}
::v-deep.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 99px;
  width: 44px;
  height: 44px;
  background-image: url("~@/assets/image/knowledgeMall/circularright.png");
  background-repeat: no-repeat;
  background-size: 44px;
  // img {
  //   width: 44px;
  //   height: 44px;
  //   position: relative;
  //   border-bottom-left-radius: 22px;
  //   border-top-left-radius: 22px;
  // }
}
.swiper-button-next:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularright2.png");
}
::v-deep.swiper-button-next:after {
  opacity: 0;
}
::v-deep.swiper-button-prev:after {
  opacity: 0;
}
::v-deep.swiper-button-prev {
  position: absolute;
  left: 0;
  top: 99px;
  width: 44px;
  height: 44px;
  background-image: url("~@/assets/image/knowledgeMall/circularleft.png");
  background-repeat: no-repeat;
  background-size: 44px;
  // img {
  //   width: 44px;
  //   height: 44px;
  //   position: relative;
  //   border-bottom-left-radius: 22px;
  //   border-top-left-radius: 22px;
  // }
}
.swiper-button-prev:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularleft2.png");
}
.industryitem {
  position: relative;
  .shopItem {
    text-align: center;
    .name {
      width: 126px;
      margin: 0 auto;
      text-align: left;
    }
    .oldPrice {
      width: 126px;
      margin: 0 auto;
      text-align: left;
    }
  }
  .icon {
    position: absolute;
    top: 77px;
    right: -12px;
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  ::v-deep.ant-pagination {
    text-align: right;
    padding-right: 29px;
    padding-top: 45px;
  }
  .contentList {
    margin-top: 24px;
    // padding: 40px;
    border-radius: 5px;
    // background: #ffffff;
    .setMealList {
      margin-bottom: 20px;
      transition: all .4s ease;
      padding: 30px;
      border-bottom: 1px solid #e1e1e1;
      transition: all 0.5s;
      border-radius: 0;
      background: #ffffff;
      .setMealShop {
        margin-top: 20px;
        background: #fafafa;
        border-radius: 4px;
        padding: 30px 30px 27px;
        display: flex;
        .item {
          display: flex;
          .icon {
            margin: 77px 30px 0;
          }
        }
        .shopItem {
          .img {
            width: 126px;
            height: 110px;
            border-radius: 4px 4px 4px 4px;
          }
          .name {
            width: 126px;
            margin-top: 4px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 21px;
            height: 42px;
          }
          .oldPrice {
            margin-top: 4px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #999999;
            line-height: 21px;
            span {
              color: #EC6C01;
            }
          }
        }
      }
    }
    .contentItem {
      display: flex;
      cursor: pointer;
      img {
        // width: 220px;
        height: 192px;
        background-repeat: no-repeat;
        object-fit: cover;
        transition: all 0.3s;
        border-radius: 4px;
      }
      .content {
        padding: 8px 24px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .left {
          width: 580px;
          .name {
            width: 580px;
            font-size: 20px;
            font-family: PingFangMedium;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            span {
              display: inline-block;
              text-align: center;
              width: 44px;
              height: 23px;
              background: rgba(21, 183, 221, 0.12);
              border-radius: 4px;
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 500;
              color: #15b7dd;
              line-height: 23px;
              margin-right: 8px;
            }
          }
          .navs {
            display: flex;
            // justify-content: space-between;
            // align-items: center;
            margin-bottom: 24px;
            word-wrap: break-word;
            word-break: normal;
            line-height: 21px;
            span {
              display: inline-block;
            }
            .type {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #1e97b4;
              line-height: 21px;
              margin-right: 16px;
            }
            .nav {
              font-size: 12px;
              font-family: PingFangMedium;
              color: #EC6C01;
              line-height: 18px;
              border-radius: 4px;
              border: 1px solid #EC6C01;
              padding: 2px 8px;
              font-weight: 400;
              margin-right: 8px;
            }
          }
          .introduce {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
        }
        .right {
          position: relative;
          padding-top: 37px;
          .positionTitle {
            // position: absolute;
            // top: 50px;
            // left: -100px;
            font-size: 16px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 24px;
          }
          .price {
            // display: flex;
            // align-items: center;
            text-align: center;
            margin-top: 19px;
            .new {
              font-size: 16px;
              font-family: PingFangMedium;
              color: #EC6C01;
              line-height: 21px;
              span {
                font-size: 28px;
              }
              .new_point {
                font-size: 16px;
              }
            }
            .old {
              font-size: 16px;
              font-family: PingFangMedium;
              font-weight: 400;
              text-decoration: line-through;
              color: #999999;
              line-height: 21px;
              // margin-left: 9px;
            }
          }
          .button {
            width: 134px;
            height: 40px;
            margin: 0 auto;
            background: #15b7dd;
            border-radius: 20px;
            margin-top: 28px;
            display: flex;
            align-items: center;
            overflow: hidden;
            justify-content: center;
            color: #ffffff;
            position: relative;
            cursor: pointer;
            p {
              margin-left: 5px;
            }
            &:hover::before {
              left: 100%;
            }
          }
          .button::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: -100%;
            background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
            transition: all .5s;
          }
        }
      }
    }
    .setMealList:hover {
      transform: translateY(-2px);
      position: relative;
      z-index: 2;
      border-radius: 5px;
      border-bottom: transparent;
      box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
      .contentItem{
        img{
          transform:scale(1.05,1.05)
        }
        .content .left .name{
          color: @theme;
        }
      }
    }
  }
}
/deep/.ant-pagination-item a {
  color: #999999;
}
/deep/.ant-pagination-item-active {
  color: #666666 !important;
}
</style>
